import React, { Fragment, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

// Context
import UserContext from '../../state-management/context/UserContext';

// Components
import Animation from '../animations/Animation';

// Consts
import results from '../../consts/results';

const customCountryRules = ["POL", "DEU"];

const Results = () => {
    // Context variables
    const [userState] = useContext(UserContext);

    // Translation hook
    const { t } = useTranslation();

    const usesCustomCountryRule = () => {
        return customCountryRules.indexOf(userState.user.isoCountryOfHire) !== -1;
    }

    const isFrom = (from) => {
        return userState.user.isoCountryOfHire === from;
    }


    if (userState.result === results.SUCCESS) {
        return (
            <div className="gov__results-container">
                <div>
                    <Animation animationName="APPROVED_RESULT_ANIMATION" elementName="gov__success-icon" />
                    <h1>{t('results.success.title')}</h1>
                    <p>{t('results.success.subtitle')}</p>

                    {/* <p className="gov__light">
                        <Trans i18nKey="results.success.content" components={{ bold: <span /> }} />
                    </p> */}
                </div>
            </div>
        );
    }

    if (userState.result === results.PENDING_F2F_CHECK) {
        return (
            <div className="gov__results-container">
                <div>
                    <Animation animationName="APPROVED_RESULT_ANIMATION" elementName="gov__success-icon" />
                    {!usesCustomCountryRule() && <>
                        <h1>{t('results.success.title')}</h1>
                        <p>{t('results.success.subtitle')}</p>
                    </>
                    }

                    {usesCustomCountryRule() && <>
                        <h1>{t('results.pending.title')}</h1>
                    </>
                    }

                    <p className="gov__light">
                        {!usesCustomCountryRule(userState.user.isoCountryOfHire) && <Trans
                            i18nKey="results.success.face-to-face-content"
                            components={{ bold: <span /> }}
                            values={{ countryOfHire: userState.user.countryOfHire }}
                        />}

                        {isFrom("POL") && <Trans
                            i18nKey="results.success.custom_polish_thank_you"
                            components={{ bold: <span /> }}
                            values={{ countryOfHire: userState.user.countryOfHire }}
                        />}

                        {isFrom("DEU") && <Trans
                            i18nKey="results.success.custom_german_thank_you"
                            components={{ bold: <span /> }}
                            values={{ countryOfHire: userState.user.countryOfHire }}
                        />}
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="gov__results-container">
            <div className="gov__landing-page-container">
                <Animation animationName="RESULT_MANUAL_CHECK" elementName="gov__pending-icon" />

                <div className="gov__landing-page-information">
                    <h1>{t('results.pending.title')}</h1>

                    {
                        userState.result === results.PENDING &&
                        <Fragment>
                            <p>{t('results.pending.manual-check.subtitle')}</p>
                            <p className="gov__light">{t('results.pending.manual-check.content')}</p>
                        </Fragment>
                    }

                    {
                        userState.result === results.PENDING_RESPONSE &&
                        <Fragment>
                            <p className="gov__light">{t('results.pending.query-response.subtitle')}</p>
                            <p className="gov__light">{t('results.pending.query-response.content')}</p>
                        </Fragment>
                    }
                </div>
            </div>
        </div>
    );
};

export default Results;
